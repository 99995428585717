import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase-config';
import { ListGroup, Container, Badge, Spinner } from 'react-bootstrap';
import './UnitSelector.css';

/**
 * UnitSelector:
 * - Fetches "units" from Firestore (or a single unit if user).
 * - Fetches "chatRooms" (if you have them) and merges with hardcoded rooms.
 * - Displays one unified list with an unread badge, using the "unit" field in Firestore messages.
 *   (Matches your ChatRoom.js approach: where("unit", "==", unitId))
 */
function UnitSelector({ onSelectUnit }) {
  const [units, setUnits] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [userUnit, setUserUnit] = useState('');
  const [userRole, setUserRole] = useState('');
  const [unreadCounts, setUnreadCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Some "hardcoded" rooms, which also rely on a "unit" field if you wish
  // but typically they'd have 'id' => we will treat them the same way
  const allHardcodedRooms = [
    { id: 'general', roomName: 'Splošno', accessibleToAll: true },
    {
      id: 'conference',
      roomName: 'Konferenca sindikatov zavarovalništva Slovenije',
      accessibleToRoles: ['admin', 'local-admin']
    }
  ];

  // Filter out any hardcoded rooms user is not allowed to see
  const getFilteredHardcodedRooms = (role) => {
    if (role === 'user') {
      // Example: exclude conference for normal users
      return allHardcodedRooms.filter(room => room.id !== 'conference');
    }
    return allHardcodedRooms;
  };

  /**
   * 1) Fetch user info (role, unit)
   * 2) Fetch relevant "units" (all if admin, or single if user)
   * 3) Fetch "chatRooms" (if you have extra rooms besides units)
   */
  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser) return;
      setIsLoading(true);

      try {
        // ---- 1) Get user info
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        let role = 'user';
        let userUnitId = '';

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          role = userData.role || 'user';
          userUnitId = userData.unit || '';
          setUserRole(role);
          setUserUnit(userUnitId);
        }

        // ---- 2) Fetch "units"
        let unitsList = [];
        if (role === 'user') {
          // A normal user only sees their own unit
          if (userUnitId) {
            const unitDocRef = doc(db, 'units', userUnitId);
            const unitDocSnap = await getDoc(unitDocRef);
            if (unitDocSnap.exists()) {
              unitsList.push({
                id: unitDocSnap.id,
                ...unitDocSnap.data()
              });
            }
          }
        } else {
          // admin/local-admin => get all
          const unitsSnapshot = await getDocs(collection(db, 'units'));
          unitsList = unitsSnapshot.docs.map(docItem => ({
            id: docItem.id,
            ...docItem.data()
          }));
        }
        setUnits(unitsList);

        // ---- 3) Fetch "chatRooms" (if you store them separately)
        let chatRoomsList = [];
        if (role === 'user') {
          // Example: user can see rooms that are accessibleToAll,
          // or that list them in allowedUsers,
          // or that match their unit (i.e. `room.unit === userUnitId`)
          const accessibleToAllQuery = query(
            collection(db, 'chatRooms'),
            where('accessibleToAll', '==', true)
          );
          const allowedUsersQuery = query(
            collection(db, 'chatRooms'),
            where('allowedUsers', 'array-contains', auth.currentUser.uid)
          );
          let unitRooms = [];
          if (userUnitId) {
            const unitQuery = query(
              collection(db, 'chatRooms'),
              where('unit', '==', userUnitId)
            );
            const unitSnap = await getDocs(unitQuery);
            unitRooms = unitSnap.docs.map(d => ({
              id: d.id,
              ...d.data()
            }));
          }

          const [accessibleSnap, allowedSnap] = await Promise.all([
            getDocs(accessibleToAllQuery),
            getDocs(allowedUsersQuery)
          ]);
          const accessibleList = accessibleSnap.docs.map(d => ({ id: d.id, ...d.data() }));
          const allowedList = allowedSnap.docs.map(d => ({ id: d.id, ...d.data() }));

          const merged = [...accessibleList, ...allowedList, ...unitRooms];
          const uniqueMap = new Map();
          merged.forEach(r => uniqueMap.set(r.id, r));
          chatRoomsList = Array.from(uniqueMap.values());
        } else {
          // admin => everything
          const allRoomsSnap = await getDocs(collection(db, 'chatRooms'));
          chatRoomsList = allRoomsSnap.docs.map(d => ({
            id: d.id,
            ...d.data()
          }));
        }
        setChatRooms(chatRoomsList);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  /**
   * Subscribe to "unread" counts for each item in real-time,
   * using the same logic as ChatRoom: "where('unit','==',<id>)".
   *
   * If there's NO lastRead timestamp for that unit => ALL messages are unread.
   */
  useEffect(() => {
    if (!auth.currentUser) return;

    const fetchUnreadCounts = async () => {
      try {
        // Get user's lastRead timestamps
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        const lastReadTimestamps = userDocSnap.data()?.lastRead?.chat || {};

        // Combine chatRooms + filteredHardcodedRooms (+ units if you want them to have chat)
        const filteredHardcoded = getFilteredHardcodedRooms(userRole);
        // If you want a single list of items that all rely on the "unit" field in messages:
        const allItems = [...filteredHardcoded, ...units, ...chatRooms];

        // For each item, we do a "where('unit','==', item.id)" query
        const unsubscribes = allItems.map(item => {
          const itemId = item.id;
          const lastRead = lastReadTimestamps[itemId]; // maybe a Timestamp or undefined

          let q;
          if (lastRead === undefined) {
            // No lastRead => all messages are unread => no time filter
            // (Can be expensive if many messages)
            q = query(
              collection(db, 'messages'),
              where('unit', '==', itemId)
            );
          } else {
            // If lastRead is set, only messages after that
            q = query(
              collection(db, 'messages'),
              where('unit', '==', itemId),
              where('createdAt', '>', lastRead),
              orderBy('createdAt')
            );
          }

          // Listen in real-time
          return onSnapshot(q, (snapshot) => {
            setUnreadCounts(prevCounts => ({
              ...prevCounts,
              [itemId]: snapshot.size
            }));
          });
        });

        // Cleanup
        return () => unsubscribes.forEach(u => u());
      } catch (error) {
        console.error('Error subscribing to unread counts:', error);
      }
    };

    fetchUnreadCounts();
  }, [chatRooms, userRole, units]);

  /**
   * Handle item click: check if it's a "chat room" or a "unit", do access checks, then call onSelectUnit.
   */
  const handleItemClick = (item) => {
    // We assume if it has "roomName", it's a chatRoom. Otherwise, it's a unit.
    const isChatRoom = !!item.roomName;

    if (isChatRoom) {
      // Access control logic for chatRooms
      if (userRole === 'admin' || userRole === 'local-admin') {
        onSelectUnit(item);
        return;
      }
      if (item.accessibleToAll) {
        onSelectUnit(item);
        return;
      }
      if (item.allowedUsers && item.allowedUsers.includes(auth.currentUser.uid)) {
        onSelectUnit(item);
        return;
      }
      if (item.unit === userUnit) {
        onSelectUnit(item);
        return;
      }
      alert('Nimate dostopa do te klepetalnice.');
    } else {
      // It's a unit. Example logic:
      if (
        item.accessibleToAll ||
        userRole === 'admin' ||
        userRole === 'local-admin' ||
        (item.accessibleToRoles && item.accessibleToRoles.includes(userRole)) ||
        item.id === userUnit
      ) {
        onSelectUnit(item);
      } else {
        alert('Nimate dostopa do te enote.');
      }
    }
  };

  /**
   * Render all items (hardcoded rooms + units + chatRooms).
   * Show unread badge if unreadCounts[item.id] > 0.
   */
  const renderList = () => {
    const filteredHardcoded = getFilteredHardcodedRooms(userRole);
    const allItems = [...filteredHardcoded, ...units, ...chatRooms];

    return allItems.map(item => {
      const name = item.roomName || item.unitName || item.id; // fallback
      const unreadCount = unreadCounts[item.id] || 0;

      return (
        <ListGroup.Item
          key={item.id}
          onClick={() => handleItemClick(item)}
          className="d-flex justify-content-between align-items-start unit-list-item"
          action
          style={{ cursor: 'pointer' }}
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{name}</div>
          </div>
          {unreadCount > 0 && (
            <Badge bg="danger" pill>
              {unreadCount}
            </Badge>
          )}
        </ListGroup.Item>
      );
    });
  };

  return (
    <Container>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100px' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <ListGroup className="unit-list">
          {renderList()}
        </ListGroup>
      )}
    </Container>
  );
}

export default UnitSelector;
